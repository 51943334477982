import { z } from "zod";

export enum CommunityTier {
  basic = "basic",
  expansion = "expansion",
  growth = "growth",
  hdp = "hdp",
}

export const communityTierEnum = z.nativeEnum(CommunityTier);
