import { z } from "zod";

export const giftHistorySchema = z.object({
  gifts: z.array(
    z.object({
      purchase_date: z.string().datetime(),
      promo_code: z.string(),
      redemption: z
        .object({
          date: z.string().datetime(),
        })
        .nullable(),
    }),
  ),
});

export type GiftHistory = z.infer<typeof giftHistorySchema>;
