"use client";

import type { Dispatch, SetStateAction } from "react";
import { useEffect, useRef, useState } from "react";

export type UseSessionStorageStateProps<T = any> = {
  defaultValue?: T;
  key: string;
};

/**
 * Returns a state and a setState action for the given sessionStorage key. When sessionStorage has not been (or cannot be)
 * accessed, returns undefined and a delayed setter instead. Always expect `state` to be `undefined` when using it. If
 * we set the state too eagerly, we overwrite the sessionStorage value and defeat the whole point. (Sometimes we'd do it
 * a hundred times because of how often this hook may be used in a single page.)
 * @template T The type of the state value (before being JSON.stringified for sessionStorage)
 * @param props
 * @param {T} [props.defaultValue=null] The default value if the given sessionStorage key is not set
 * @param {string} props.key The key to use for our state in sessionStorage
 * @returns {[T|undefined, Dispatch<SetStateAction<T>>]}
 */
export const useSessionStorageState = <T = any>({
  defaultValue = null,
  key,
}: UseSessionStorageStateProps<T>):
  | readonly [T, Dispatch<SetStateAction<T>>]
  | readonly [] => {
  const [state, setState] = useState<null | T>(null);
  const [status, setStatus] = useState<"pending" | "resolved">("pending");
  const [changeStatus, setChangeStatus] = useState<"inert" | "requested">(
    "inert",
  );
  const pendingChange = useRef<T>(null);
  const statusRef = useRef<typeof status>(status);

  useEffect(() => {
    if (typeof sessionStorage !== "undefined") {
      setState(
        JSON.parse(sessionStorage.getItem(key) ?? JSON.stringify(defaultValue)),
      );
      setStatus("resolved");
    }
  }, []);

  useEffect(() => {
    if (typeof sessionStorage !== "undefined" && status === "resolved") {
      sessionStorage.setItem(key, JSON.stringify(state));
    }
  }, [state, status]);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    if (status === "resolved" && changeStatus === "requested") {
      setState(pendingChange.current);
      setChangeStatus("inert");
    }
  }, [status, changeStatus]);

  /**
   * This needs to use refs to allow media elements to preserve their state
   * @param newVal
   */
  const updateState = (newVal: T) => {
    if (statusRef.current === "pending") {
      pendingChange.current = newVal;
      setChangeStatus("requested");
    } else {
      setState(newVal);
    }
  };

  return status === "pending"
    ? ([undefined, updateState] as const)
    : ([state, setState] as const);
};
