import type { Locale } from "@packages/i18n";
import { useLocale } from "@packages/i18n";
import { useEffect, useRef } from "react";

import { APP_SESSION_STORAGE_KEY, useLocalStorageState, uuid } from "../../lib";
import { useSession } from "../../session";

export const useHeadersValues = () => {
  const [appSession] = useLocalStorageState<string>({
    key: APP_SESSION_STORAGE_KEY,
    defaultValue: uuid(),
  });
  const locale = useLocale() as Locale;
  const { token } = useSession();

  const headersValues = useRef<{
    appSession: string;
    locale: Locale;
    token: string;
  }>({ appSession, locale, token });

  useEffect(() => {
    headersValues.current = { appSession, locale, token };
  }, [appSession, locale, token]);

  return headersValues;
};
