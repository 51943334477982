"use client";

import { useParams } from "next/navigation";

import { usePathname, useRouter } from "../lib";
import type { Locale } from "../types";

export const useSetLocale = () => {
  const params = useParams();
  const pathname = usePathname();
  const router = useRouter();

  const setLocale = ({ locale }: { locale: Locale }) =>
    router.push(
      /** {@see https://next-intl.dev/docs/routing/navigation#userouter-change-locale} */
      // @ts-expect-error
      { pathname, params },
      { locale },
    );

  return { setLocale };
};
