"use client";

import { useRouter } from "@packages/i18n";

import { useSession } from "../session";

export const useRedirects = () => {
  const { status } = useSession();
  const router = useRouter();

  const isAuthenticated = status === "authenticated";

  const redirectToSubscribe = async ({
    newWindow = false,
  }: { newWindow?: boolean } = {}) => {
    const url = isAuthenticated
      ? "/settings/subscription"
      : `/login?redirect=${encodeURIComponent("/settings/subscription")}`;
    if (newWindow) {
      window.open(url);
    } else {
      router.push(url);
    }
  };

  return { redirectToSubscribe };
};
