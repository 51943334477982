"use client";

import { billingRedirect, type PaymentRedirect } from "../../../lib";
import { useRequestMutation } from "../../../request";

type BillingInit = PaymentRedirect & {
  priceId: string;
  promoCode?: string;
  successRedirectUrl?: string;
  failureRedirectUrl?: string;
};

export const useRequestBilling = () => {
  return {
    mutationInitSubscription: useRequestMutation({
      mutationFnRequestProps: (data: BillingInit) => ({
        body: JSON.stringify({
          payment_method: data.paymentMethod,
          price_id: data.priceId,
          promo_code: data.promoCode,
          billing_email: data.email,
          billing_name: data.name,
          document: data.document,
          success_redirect_url: data.successRedirectUrl,
          failure_redirect_url: data.failureRedirectUrl,
        }),
        method: "POST",
        schema: billingRedirect,
        urlBackendPath: "/billing-subscription/initialize",
      }),
    }),
  };
};
