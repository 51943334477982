import { giftDetailsSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { useSession } from "../../../session";
import { keyGiftDetails } from "./keyGiftDetails";

export type UseRequestGiftDetailsProps = UseRequestQueryProps & {
  promoCode: string;
};

export const useRequestGiftDetails = ({
  promoCode,
  ...props
}: UseRequestGiftDetailsProps) => {
  const { status } = useSession();

  return {
    query: useRequestQuery({
      enabledWithoutSessionToken: false,
      queryFnRequestProps: {
        urlBackendPath: `/gift/${promoCode}`,
        requestType: "authenticated",
        schema: giftDetailsSchema,
      },
      queryKey: keyGiftDetails({ promoCode }),
      ...props,
    }),
    publicQuery: useRequestQuery({
      enabledWithoutSessionToken: true,
      enabled: status === "unauthenticated",
      queryFnRequestProps: {
        urlBackendPath: `/public/gift/${promoCode}`,
        requestType: "other",
        schema: giftDetailsSchema,
      },
    }),
  };
};
