import type { BaseKeyFunction } from "../../../lib";

export const keyCart: BaseKeyFunction<{
  priceId?: string;
  promoCode?: string;
}> = ({ priceId, promoCode }) => ["cart", priceId, promoCode];

export const keyPublicCart: BaseKeyFunction<{
  priceId?: string;
  promoCode?: string;
}> = ({ priceId, promoCode }) => ["public-cart", priceId, promoCode];
