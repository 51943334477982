"use client";

import type { Locale } from "@packages/i18n";

import type { SearchResultSchema } from "../../../lib";
import { searchResultSchema, URL_SEARCH } from "../../../lib";
import type { UseRequestInfiniteQueryProps } from "../../../request";
import { useRequestInfiniteQuery } from "../../../request";
import { ELASTIC_SEARCH_DIRECTORY, PAGE_SIZE_SEARCH } from "./constants";
import { keySearch } from "./keySearch";
import type { SearchItemType } from "./types";

export type UseRequestSearchProps = Partial<UseRequestInfiniteQueryProps> & {
  filters: {
    locale: Locale;
    type?: SearchItemType;
  };
  search: string;
};

export const useRequestSearch = ({
  filters,
  search,
}: UseRequestSearchProps) => ({
  infiniteQuery: useRequestInfiniteQuery({
    enabledWithoutSessionToken: true,
    getNextPageParam: (lastPage: SearchResultSchema) =>
      lastPage.meta.page.current < lastPage.meta.page.total_pages
        ? lastPage.meta.page.current + 1
        : null,
    initialPageParam: 1,
    queryFnRequestProps: ({ pageParam }) => ({
      body: JSON.stringify({
        filters: {
          all: [
            {
              languages: [filters.locale],
            },
            {
              type: filters.type
                ? [filters.type]
                : [
                    "page",
                    "collection",
                    "prayer",
                    "radio_station",
                    "setting",
                    "navigation",
                  ],
            },
          ],
        },
        page: {
          current: pageParam,
          size: PAGE_SIZE_SEARCH,
        },
        query: search,
      }),
      method: "POST",
      requestType: "search",
      schema: searchResultSchema,
      url: `${URL_SEARCH}/${ELASTIC_SEARCH_DIRECTORY}/search`,
    }),
    queryKey: keySearch({ filters, search }),
  }),
});
