"use client";

import { useLocale, usePathname } from "@packages/i18n";

import { pathMatchFn } from "./pathMatchFn";

export const usePathMatch = () => {
  const pathname = usePathname();
  const locale = useLocale();

  return (href: string, exact: boolean = false) =>
    pathMatchFn(href, exact, pathname, locale);
};
