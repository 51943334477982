"use client";

import { usePathname as usePathnameNextIntl } from "../lib";

export const usePathname = () => {
  const pathname = usePathnameNextIntl();

  /** Override /index/<DATE> */
  if (pathname.includes("/index")) return "/";

  return pathname;
};
