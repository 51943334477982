import { getTranslations } from "next-intl/server";
import { Fragment } from "react";

import type { BaseI18nMessageProps } from "./types";

export type I18nMessageServerProps = BaseI18nMessageProps;

export const I18nMessageServer = async ({
  formats,
  message,
  values,
}: I18nMessageServerProps) => {
  const t = await getTranslations();

  return <Fragment>{t(message, values, formats)}</Fragment>;
};
