import { z } from "zod";

import { parishSearchItemSchema } from "./parishSearchItemSchema";
import { searchMetaSchema } from "./searchMetaSchema";

export const parishSearchResultSchema = z.object({
  meta: searchMetaSchema,
  results: parishSearchItemSchema.array(),
});

export type ParishSearchResult = z.infer<typeof parishSearchResultSchema>;
