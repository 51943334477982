import type { Me, User } from "../lib";

export type GetNameProps = {
  user: Me | User;
};

export const getName = ({ user }: GetNameProps) => {
  if (user.name) {
    if (user.last_name) return `${user.name} ${user.last_name}`;

    return user.name;
  }

  return user.last_name ?? "";
};
