import { locales } from "@packages/i18n";
import type { ZodLiteral } from "zod";
import { z } from "zod";

export const homeMetaSchema = z.record(
  z.union(
    locales.map((str) => z.literal(str)) as [
      ZodLiteral<string>,
      ZodLiteral<string>,
      ...ZodLiteral<string>[],
    ],
  ),
  z.object({
    title: z.string(),
    description: z.string(),
  }),
);

export type WordpressHomeMeta = z.infer<typeof homeMetaSchema>;
