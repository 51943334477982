import { merge } from "ts-deepmerge";

export type MergeMessagesProps = {
  defaultMessages: any;
  userMessages: any;
};

export const mergeMessages = ({
  defaultMessages,
  userMessages,
}: MergeMessagesProps) => merge(defaultMessages, userMessages);
