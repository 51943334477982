import { locales } from "@packages/i18n";
import { z, type ZodLiteral } from "zod";

export const homeHeroSchema = z.object({
  title: z.string(),
  subtitle: z.string(),
  branch_link: z.string().url(),
  button_text: z.string(),
});

export const allHeroesSchema = z.record(
  z.union(
    locales.map((str) => z.literal(str)) as [
      ZodLiteral<string>,
      ZodLiteral<string>,
      ...ZodLiteral<string>[],
    ],
  ),
  homeHeroSchema,
);

export type HomeHero = z.infer<typeof homeHeroSchema>;
export type AllHomeHeroes = z.infer<typeof allHeroesSchema>;
