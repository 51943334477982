import type { Locale } from "@packages/i18n";

import { determineClientOrServer } from "./determineClientOrServer";

export type GetI18nRequestHeadersProps = {
  valueLocale?: Locale;
};

export const getI18nRequestHeaders = async ({
  valueLocale,
}: GetI18nRequestHeadersProps): Promise<HeadersInit> => {
  if (valueLocale) return { "X-Hallow-Language": valueLocale };

  if (determineClientOrServer() === "server") {
    const { getLocale } = await import("@packages/i18n/server");
    return { "X-Hallow-Language": await getLocale() };
  }
};
