import { z } from "zod";

import { userSchema } from "../user";

export const giftDetailsSchema = z.object({
  purchaser: userSchema.nullable(),
  name: z.string(),
  trial_days: z.number(),
  redemption_error: z
    .object({
      status: z.literal("error"),
      code: z.number(),
      error: z.string(),
    })
    .nullable(),
});

export type GiftDetails = z.infer<typeof giftDetailsSchema>;
