"use client";

import type { UseRequestMutationProps } from "../../../request";
import { useRequestMutation } from "../../../request";

export type CoppaData = {
  birthdate?: string;
  email: string;
  first_name?: string;
  last_name?: string;
  parent_email?: string;
  parent_first_name?: string;
  parent_last_name?: string;
  parent_promo_code?: string;
  password: string;
  school?: string;
  utm_campaign?: string;
};

export type UseRequestCoppaProps = UseRequestMutationProps;

export const useRequestCoppa = ({ ...props }: UseRequestCoppaProps = {}) => ({
  ...props,
  mutationPost: useRequestMutation({
    mutationFnRequestProps: (data: CoppaData) => ({
      body: JSON.stringify(data),
      method: "POST",
      requestType: "authenticated",
      urlBackendPath: "/coppa",
    }),
  }),
});
