import { giftHistorySchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyGiftHistory } from "./keyGiftHistory";

export const useRequestGiftHistory = () => ({
  query: useRequestQuery({
    queryFnRequestProps: {
      urlBackendPath: "/gift/history",
      schema: giftHistorySchema,
    },
    queryKey: keyGiftHistory(),
  }),
});
