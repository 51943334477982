"use client";

import { useMessages, useTranslations } from "next-intl";

export const useErrorlessTranslations = () => {
  const messages = useMessages();
  const tBase = useTranslations();
  return ((key: string, values?: any, formats?: any) => {
    if (key in messages) {
      return tBase(key, values, formats);
    } else {
      return key;
    }
  }) as typeof tBase;
};
