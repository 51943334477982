"use client";

import { URL_SEARCH } from "../../../lib";
import { parishSearchResultSchema } from "../../../lib/types/schema/search/parishSearchResultSchema";
import type { UseRequestInfiniteQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { PARISH_SEARCH_DIRECTORY } from "./constants";
import { keyParishSearch } from "./keyParishSearch";

export type UseRequestParishSearchProps =
  Partial<UseRequestInfiniteQueryProps> & {
    filters?: {
      status?: Array<"active" | "launched">;
    };
    search: string;
  };

export const useRequestParishSearch = ({
  filters = { status: ["active", "launched"] },
  search,
}: UseRequestParishSearchProps) => ({
  query: useRequestQuery({
    enabledWithoutSessionToken: true,
    queryFnRequestProps: () => ({
      body: JSON.stringify({
        filters,
        query: search,
      }),
      method: "POST",
      requestType: "search",
      schema: parishSearchResultSchema,
      url: `${URL_SEARCH}/${PARISH_SEARCH_DIRECTORY}/search`,
    }),
    queryKey: keyParishSearch({ filters, search }),
  }),
});
