import { z } from "zod";

import { communitySchema } from "../community";
import { playlistContentSchema } from "../playlist/playlistContentResponseSchema";
import campaignMessageSchema from "./campaignMessageSchema";
import { campaignSchema } from "./campaignSchema";

export const campaignDetailsSchema = z.object({
  campaign: campaignSchema,
  playlist_content: z.array(playlistContentSchema),
  community: communitySchema.optional().nullable(),
  latest_posts: z.array(campaignMessageSchema).optional(),
  updates: z.array(campaignMessageSchema),
});

export type CampaignDetails = z.infer<typeof campaignDetailsSchema>;
