import type { IntlConfig } from "next-intl";
import { IntlErrorCode } from "next-intl";

import type { Locale } from "../types";
import { consoleErrorPackagesi18n } from "./consoleErrorPackagesi18n";
import type { MergeMessagesProps } from "./mergeMessages";
import { mergeMessages } from "./mergeMessages";

export type Basei18nProps = MergeMessagesProps & {
  locale: Locale;
  requestConfig?: Omit<IntlConfig, "locale">;
};

export const basei18n = ({
  defaultMessages,
  locale,
  requestConfig,
  userMessages,
}: Basei18nProps): IntlConfig => ({
  getMessageFallback({ namespace, key, error }) {
    const message = [namespace, key].filter((part) => part != null).join(".");
    if (error.code === IntlErrorCode.MISSING_MESSAGE)
      return `${message}: message not translated`;
    else {
      consoleErrorPackagesi18n({
        heading: `${message} (${error.code})`,
        error: error.message,
      });
      return `${message} (${error.code}): ${error.message}`;
    }
  },
  locale,
  messages: mergeMessages({ defaultMessages, userMessages }),
  onError(error) {
    if (error.code === IntlErrorCode.MISSING_MESSAGE)
      consoleErrorPackagesi18n({ heading: error.code, error: error.message });
    else throw error;
  },
  timeZone: "UTC",
  ...requestConfig,
});
