import { z } from "zod";

import { campaignStatusEnum } from "../../enums";
import { imagesSchema } from "../imagesSchema";
import { userSchema } from "../user/userSchema";
import { givingSchema } from "./givingSchema";

export const campaignSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  starts_at: z.string(),
  ends_at: z.string(),
  organizer: userSchema.pick({
    id: true,
    name: true,
    last_name: true,
    username: true,
    image_url: true,
    bio: true,
    is_self: true,
    is_friend: true,
  }),
  labels: z.array(z.string()),
  status: campaignStatusEnum,
  content_title: z.string(),
  is_hallow_campaign: z.boolean(),
  sessions_prayed: z.number(),
  has_joined: z.boolean(),
  image: imagesSchema.nullable(),
  content_image: imagesSchema,
  giving: givingSchema.nullable(),
});

export type Campaign = z.infer<typeof campaignSchema>;
