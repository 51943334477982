"use client";

import { IntlErrorCode, NextIntlClientProvider } from "next-intl";
import type { ComponentProps } from "react";
import { Suspense } from "react";

export type I18nProviderProps = ComponentProps<
  typeof NextIntlClientProvider
> & {
  isOnErrorMessagesWithUnderscoresOnly?: boolean;
};

export const I18nProvider = ({
  children,
  isOnErrorMessagesWithUnderscoresOnly,
  ...props
}: I18nProviderProps) => {
  /**
   * Handle errors on messages with underscores ONLY
   */
  if (isOnErrorMessagesWithUnderscoresOnly)
    props.onError = (error) => {
      if (
        error.code === IntlErrorCode.MISSING_MESSAGE &&
        error.originalMessage.includes("_")
      )
        console.error(error);
    };

  return (
    <Suspense>
      <NextIntlClientProvider timeZone="UTC" {...props}>
        {children}
      </NextIntlClientProvider>
    </Suspense>
  );
};
