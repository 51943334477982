export type AppendSearchParamToUrlProps = {
  key: string;
  url: string;
  value: string;
};

export const appendSearchParamToUrl = ({
  key,
  url,
  value,
}: AppendSearchParamToUrlProps) => {
  try {
    const parsedUrl = new URL(url, window.location.href);
    parsedUrl.searchParams.append(key, value);
    return parsedUrl.toString();
  } catch (error) {
    return `${url}?${key}=${value}`;
  }
};
