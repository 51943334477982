import { useQueryClient } from "@tanstack/react-query";

import { subscriptionSchema } from "../../../lib";
import { useRequestMutation } from "../../../request";
import { keyGiftDetails } from "./keyGiftDetails";

export const useRequestGiftRedeem = ({ promoCode, ...props }) => {
  const queryClient = useQueryClient();

  return {
    mutatePost: useRequestMutation<typeof subscriptionSchema>({
      mutationFnRequestProps: {
        urlBackendPath: "/gift/redeem",
        requestType: "authenticated",
        schema: subscriptionSchema,
        body: JSON.stringify({ promo_code: promoCode }),
      },
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: keyGiftDetails({ promoCode }),
        }),
    }),
  };
};
