import { radioStationDetailsSchema } from "../../../lib";
import { request } from "../../../request";

export type RequestRadioStationTrustedProps = {
  id: number;
};

export const requestRadioStationTrusted = ({
  id,
}: RequestRadioStationTrustedProps) =>
  request<typeof radioStationDetailsSchema>({
    next: {
      tags: ["radio-station", `radio-station-${id}`],
      revalidate: 60 * 60,
    },
    requestType: "trusted",
    schema: radioStationDetailsSchema,
    urlBackendPath: `/trusted/radio-station-details/${id}`,
  });
