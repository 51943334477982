import type { Community } from "../lib";
import { CommunityType } from "../lib";

export type GetCommunityMemberCountProps = {
  community: Community;
};

export const getCommunityMemberCount = ({
  community,
}: GetCommunityMemberCountProps) => {
  if (community?.type === CommunityType.network)
    return community?.member_summary?.network_member_count;

  return community?.member_summary?.member_count;
};
