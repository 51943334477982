"use client";

import { productsSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { useSession } from "../../../session";
import { keyPlans, keyPublicPlans } from "./keyProducts";

export const useRequestProducts = () => {
  const { status } = useSession();

  return {
    query: useRequestQuery({
      queryFnRequestProps: {
        schema: productsSchema,
        urlBackendPath: "/products/plans",
      },
      queryKey: keyPlans(),
    }),
    publicQuery: useRequestQuery({
      enabled: status !== "authenticated",
      enabledWithoutSessionToken: true,
      queryFnRequestProps: {
        schema: productsSchema,
        urlBackendPath: "/public/products",
        requestType: "other",
      },
      queryKey: keyPublicPlans(),
    }),
  };
};
