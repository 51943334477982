"use client";

import type { QueryClientProviderProps } from "@tanstack/react-query";

import { DataProvider } from "../data";
import { SessionProvider } from "../session";
import { LocaleProvider } from "./LocaleProvider";

export type SdkProviderProps = Omit<QueryClientProviderProps, "client">;

export const SdkProvider = ({ children, ...props }: SdkProviderProps) => (
  <SessionProvider>
    <DataProvider {...props}>
      <LocaleProvider>{children}</LocaleProvider>
    </DataProvider>
  </SessionProvider>
);
