import { z } from "zod";

import { rawNumberSchema } from "./rawNumberSchema";
import { rawStringSchema } from "./rawStringSchema";

export const parishSearchItemSchema = z.object({
  city: rawStringSchema,
  street_address: rawStringSchema,
  region: rawStringSchema,
  postal_code: rawStringSchema,
  name: rawStringSchema,
  id: rawNumberSchema,
});

export type ParishSearchItem = z.infer<typeof parishSearchItemSchema>;
