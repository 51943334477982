import { createNavigation } from "next-intl/navigation";
import type { ComponentProps } from "react";

import { routing } from "./routing";

const {
  getPathname,
  Link,
  permanentRedirect,
  redirect,
  usePathname,
  useRouter,
} = createNavigation(routing);

export type LinkProps = ComponentProps<typeof Link>;

export {
  getPathname,
  Link,
  permanentRedirect,
  redirect,
  usePathname,
  useRouter,
};
